/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // WoW Animations
                new WOW().init();
                // Placeholders
                if (document.createElement("input").placeholder !== undefined) {
                    $('html').addClass('placeholders');
                }
                // Fancybox projects
                $(document).on('click', '.fancybox-projects', function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    $.ajax({
                        url: AJAX_URL,
                        method: 'POST',
                        data: {
                            action: 'project_gallery',
                            security: FORM_SECURITY,
                            project_id: $this.data('project-id')
                        },
                        success: function (data) {
                            $.fancybox.open(data, {
                                padding: 0,
                                type: 'image',
                                beforeShow: function () {
                                    this.title = $this.data("caption");
                                }
                            });
                        }
                    });
                });
                // Responsive nav-tabs to <select>
                $(".nav-tabs-responsive-select").on('change', function () {
                    $('a[href="' + $(this).val() + '"]').tab('show');
                });
                // SMOOTH SCROLL
                juizScrollTo('a[href^="#"]');
                // Google map
                function init() {
                    var myLatlng = new google.maps.LatLng(GMAP_COORDS.lat, GMAP_COORDS.lng);
                    var mapOptions = {
                        center: myLatlng,
                        zoom: GMAP_ZOOM,
                        scrollwheel: false,
                        draggable: false,
                        styles: GMAP_STYLES
                    };
                    // MAP
                    var map = new google.maps.Map(document.getElementById('gmap'),
                            mapOptions);
                    // Infowindow
                    var infowindow = new google.maps.InfoWindow({
                        content: GMAP_INFOWINDOW
                    });
                    // Marker
                    var marker = new google.maps.Marker({
                        position: myLatlng,
                        map: map,
                        icon: GMAP_MARKER
                    });
                    marker.setMap(map);
                    // Add click event on marker
                    google.maps.event.addListener(marker, 'click', function () {
                        infowindow.open(map, marker);
                    });
                }
                if ($('#gmap').length) {
                    google.maps.event.addDomListener(window, 'load', init);
                }
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

    function juizScrollTo(element) {
        $(element).click(function (e) {
            var goscroll = false;
            var the_hash = $(this).attr("href");
            var regex = new RegExp("\#(.*)", "gi");
            var the_element = '';

            if (the_hash.match("\#(.*)") && !$(this).hasClass('noscroll')) {
                if (the_hash === "#") {
                    the_element = 'html';
                    goscroll = true;
                }
                the_hash = the_hash.replace(regex, "$1");

                if ($("#" + the_hash).length) {
                    the_element = "#" + the_hash;
                    goscroll = true;
                }
                else if ($("a[name=" + the_hash + "]").length) {
                    the_element = "a[name=" + the_hash + "]";
                    goscroll = true;
                }

                if ($(e.target).is('a')) {
                    $(this).parents('.collapse').collapse('hide');
                }

                if (goscroll) {
                    var scrollToOffset = $(the_element).offset().top;
                    $(this).parents('.navbar-collapse').find('li').removeClass('active');
                    $(this).parents('li').addClass('active');
                    if ($('body').data('offset')) {
                        scrollToOffset = scrollToOffset - $('body').data('offset');

                    }
                    $('html, body').animate({
                        scrollTop: scrollToOffset
                    }, 'slow');
                    return false;
                }
            }
        });
    }

})(jQuery); // Fully reference jQuery after this point.
